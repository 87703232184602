.slide {
	&-wrap {
		& * {
			@apply select-none;
			-webkit-user-drag: none;
		}

		& .slide {
			&-list {
				& > li {
					@apply w-full flex-none self-start;
				}

				&.auto {
					& > li {
						@apply w-auto;
					}
				}

				&.move {
					& a {
						@apply pointer-events-none;
					}
				}
			}

			&-indicator {
				& > ul {
					@apply flex w-full items-center justify-center;

					& > li {
						@apply m-1 size-2 flex-none;

						& button {
							@apply block size-full overflow-hidden rounded-full bg-dg-black/30 text-0 leading-0 transition-all hover:bg-dg-black dark:bg-dgdark-black/30 dark:hover:bg-dgdark-black;

							&.selected {
								@apply bg-dg-black dark:bg-dgdark-black;
							}
						}
					}
				}
			}
		}
	}
}

#wrap {
	& .btn {
		&-top {
			@apply bottom-5;
			bottom: calc(theme("spacing.5") + env(safe-area-inset-bottom));

			&.hide {
				@apply -bottom-10 !important;
				bottom: calc(- theme("spacing.10") - env(safe-area-inset-bottom)) !important;
			}
		}
	}

	&.hide {
		&-loading {
			& #loading {
				&-common {
					@apply hidden;
				}
			}
		}
	}
}

.app {
	& #wrap {
		& .btn {
			&-top {
				@apply bottom-20;
				bottom: calc(theme("spacing.20") + env(safe-area-inset-bottom));

				&.hide {
					@apply -bottom-10;
				}
			}
		}
	}
}

.horizon {
	&-12 {
		&-0 {
			@include horizonScroll(12px, 0);
		}
	}

	&-14 {
		&-6 {
			@include horizonScroll(14px, 6px);
		}
	}

	&-16 {
		&-0 {
			@include horizonScroll(16px, 0);
		}
	}

	&-24 {
		&-4 {
			@include horizonScroll(24px, 4px);
		}

		&-8 {
			@include horizonScroll(24px, 8px);
		}

		&-14 {
			@include horizonScroll(24px, 14px);
		}
	}
}

/*
DARKMODE
@media (prefers-color-scheme : dark) {
	body {
		background-color: #000;
	}
}

@media (prefers-color-scheme : light) {
	body {
		background: var(--grayscaleWhite);
	}
}
*/

.toast {
	&-wrap {
		& > div {
			@apply bottom-7.5;
			bottom: calc(theme("spacing.[7.5]") + env(safe-area-inset-bottom));
		}
	}
}

.app {
	.toast {
		&-wrap {
			& > div {
				@apply bottom-22.5;
				bottom: calc(theme("spacing.[22.5]") + env(safe-area-inset-bottom));
			}
		}
	}
}

html,
body {
	@apply font-qoo10M;
	font-weight: var(--defaultFontWeight);
	font-size: var(--defaultFontSize);
	line-height: var(--defaultLineHeight);

	&.pc {
		@apply font-qoo10Pc;
	}
}

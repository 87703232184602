#wrap {
	&-footer {
		& > ul {
			@apply pb-0;
			padding-bottom: calc(theme("spacing.0") + env(safe-area-inset-bottom));

			&::before {
				@apply absolute bottom-full h-2 w-full content-[''];
				background-image: linear-gradient(
					180deg,
					rgb(0 0 0 / 0) 0%,
					rgb(0 0 0 / 0.01) 5.73%,
					rgb(0 0 0 / 0.05) 100%
				);
			}
		}

		& ~ .btn {
			&-top {
				@apply bottom-20 !important;
				bottom: calc(theme("spacing.20") + env(safe-area-inset-bottom)) !important;
			}
		}

		& ~ #footer {
			&-info {
				@apply mb-13.5;
				margin-bottom: calc(theme("spacing[13.5]") + env(safe-area-inset-bottom));
			}
		}

		&.empty {
			margin-bottom: env(safe-area-inset-bottom);
		}
	}
}

#layerpopup {
	&-recentitems {
		@apply rounded-none;

		& .layerpopup {
			&-header {
				@apply py-4;

				& > strong {
					@apply pt-2 after:hidden;
				}

				& > button {
					@apply hidden;
				}
			}

			&-content {
				@apply pb-16.5 pt-0;
				padding-bottom: calc(theme("spacing.[16.5]") + env(safe-area-inset-bottom));

				& > ul {
					& li {
						& button {
							&.btn {
								&-heart {
									& > svg {
										& path {
											@apply fill-dg-white transition-colors dark:fill-dgdark-white;
										}
									}

									&.text {
										&-dg {
											&-red {
												& svg {
													& path {
														@apply fill-dg-red dark:fill-dgdark-red;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.app {
	#footer {
		&-info {
			@apply pb-25;
			padding-bottom: calc(theme("spacing.25") + env(safe-area-inset-bottom));

			& > a {
				@apply bottom-28;
				bottom: calc(theme("spacing.28") + env(safe-area-inset-bottom));
			}
		}
	}
}

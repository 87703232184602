.layerpopup {
	&-wrap {
		& > div {
			& > .layerpopup {
				&-content {
					-webkit-overflow-scrolling: auto;
				}

				&-footer {
					& button {
						@apply flex min-h-12 flex-1 items-center justify-center;
					}
				}
			}

			&.w {
				&-full {
					& > .layerpopup {
						&-footer {
							@apply bottom-0;
							bottom: calc(theme("spacing.0") + env(safe-area-inset-bottom));
						}
					}
				}
			}

			&#layerpopup {
				&-searchlayer {
					@apply pb-0;

					& > .layerpopup {
						&-header {
							@apply hidden;
						}

						&-content {
							@apply p-0;
						}
					}
				}

				&-share {
					& > .layerpopup {
						&-header {
							@apply min-h-3.5;

							& strong {
								@apply hidden;
							}

							& button {
								@apply -mt-8.5 bg-center bg-no-repeat after:hidden;
								background-image: url("data:image/svg+xml,%3Csvg width='39' height='13' viewBox='0 0 39 13' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath opacity='0.96' fill-rule='evenodd' clip-rule='evenodd' d='M4.15252 0.877483C2.88157 0.337996 1.41392 0.930966 0.874432 2.20192C0.334945 3.47287 0.927914 4.94052 2.19887 5.48001L17.8474 12.1224C18.3917 12.3534 18.972 12.3768 19.5 12.2282C20.028 12.3768 20.6083 12.3534 21.1525 12.1224L36.8011 5.48001C38.0721 4.94052 38.665 3.47287 38.1255 2.20192C37.5861 0.930966 36.1184 0.337996 34.8475 0.877483L19.5 7.3921L4.15252 0.877483Z' fill='%23B6B6B6' /%3E%3C/svg%3E");
							}
						}
					}

					&.translate {
						&-y {
							&-full {
								& > .layerpopup {
									&-header {
										& button {
											@apply hidden;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.list {
	&.grid {
		&-2 {
			@apply grid grid-cols-2;
		}
	}

	& .max {
		&-line {
			&-clamp {
				&-2 {
					&sm {
						@include textLineClamp(2, 1.25rem, auto);
					}
				}
			}
		}
	}

	& .line {
		&-clamp {
			&-2 {
				@include textLineClamp(2, 1.25rem, auto);
			}
		}
	}
}

@tailwind base;

@tailwind components;

@tailwind utilities;

@layer components {
	.table {
		@apply w-full border-separate border-spacing-0 border border-solid border-dg-500 dark:border-dgdark-500;

		&-wrap {
			@apply max-h-125 overflow-hidden overflow-y-auto rounded-lg border border-solid border-dg-500 dark:border-dgdark-500;

			& .table {
				@apply border-none;
			}
		}

		& th {
			@apply border-b border-r border-solid border-dg-500 px-1 py-3 text-center align-middle text-dg-800 dark:border-dgdark-500 dark:text-dgdark-800;

			&:last-child {
				@apply border-r-0;
			}
		}

		& thead {
			@apply sticky left-0 top-0;

			& tr {
				@apply bg-dg-300 transition-colors dark:bg-dgdark-300;
			}
		}

		& tbody {
			& tr {
				@apply transition-colors;

				& th {
					@apply bg-dg-300 dark:bg-dgdark-300;
				}

				& td {
					@apply break-all border-r border-t border-solid border-dg-500 p-3 text-center align-middle text-dg-900 dark:border-dgdark-500 dark:text-dgdark-900;

					&:last-child {
						@apply border-r-0;
					}

					&:only-child {
						@apply text-center;
					}
				}

				&:first-child {
					& td {
						@apply border-t-0;
					}
				}
			}
		}

		&.fit {
			@apply min-w-full;
		}
	}

	.toggle {
		@apply sr-only relative block h-8 w-13.5;

		& input {
			@apply hidden;

			& + span {
				@apply absolute left-0 top-0 !m-0 block h-full w-full overflow-hidden rounded-2xl bg-dg-500 text-0 transition-bgColor dark:bg-dgdark-500;

				&::after {
					@apply absolute left-0.5 top-0.5 flex h-7 w-7 rounded-full bg-dg-white content-[''] dark:bg-dgdark-white;
					box-shadow:
						0 3px 8px rgb(0 0 0 / 0.15),
						0 3px 1px rgb(0 0 0 / 0.06);
					animation: promotion1 0.4s ease-in-out;

					@keyframes promotion1 {
						0% {
							left: calc(100% - theme("spacing[7.5]"));
						}

						60% {
							@apply left-0;
						}

						100% {
							@apply left-0.5;
						}
					}
				}
			}

			&:disabled {
				& + span {
					@apply !bg-dg-400 dark:!bg-dgdark-400;
				}
			}

			&:checked {
				& + span {
					@apply bg-dg-red dark:bg-dgdark-red;

					&::after {
						@apply content-[''];
						left: calc(100% - theme("spacing[7.5]"));
						animation: promotion2 0.4s ease-in-out;

						@keyframes promotion2 {
							0% {
								@apply left-0.5;
							}

							60% {
								left: calc(100% - theme("spacing.7"));
							}

							100% {
								left: calc(100% - theme("spacing[7.5]"));
							}
						}
					}
				}

				&:disabled {
					& + span {
						@apply bg-dg-red/10 dark:bg-dgdark-red/10;
					}
				}
			}
		}
	}
}
